:root {
  --bg-color: black;

  --primary-color: #fa1b86;
  --secondary-color: #ff8bc3;
  --text-color: #8a8a8a;
  --shadow-color: #333333;
  --glitch-shadow-color1: #2a96d4;
  --glitch-shadow-color2: #cc2a1f;

  --typewriterSpeed: 1.5s;
  --typewriterCharacters: 9;

  --hoverSpeed: 300ms;
}
