html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url('/src/assets/mouse.svg'), auto;
  height: 2900vh;
  background-color: var(--bg-color);
}
