@font-face {
  /* 사용할 폰트의 이름 */
  font-family: 'VCR_OSD_MONO';
  font-display: fallback;

  /* 
    src: 사용할 폰트의 위치 및 형식 
       Safari, Android, iOS => ttf
       Modern Browsers => woff
       IE6-IE8 => eot
    */
  src: url('./VCR_OSD_MONO.ttf') format('truetype'),
    url('./VCR_OSD_MONO.woff') format('woff'),
    url('./VCR_OSD_MONO.eot?iefix') format('embedded-opentype');

  /* 사용할 폰트의 기본 스타일 */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  /* 사용할 폰트의 이름 */
  font-family: 'digital_7';
  font-display: fallback;

  /* 
    src: 사용할 폰트의 위치 및 형식 
       Safari, Android, iOS => ttf
       Modern Browsers => woff
       IE6-IE8 => eot
    */
  src: url('./digital_7.ttf') format('truetype'),
    url('./digital_7.woff') format('woff'),
    url('./digital_7.eot?iefix') format('embedded-opentype');

  /* 사용할 폰트의 기본 스타일 */
  font-weight: 400;
  font-style: normal;
}
